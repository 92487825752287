import React, { useContext } from "react"
import styled from "styled-components"
import Switch from "react-switch"

import { ThemeContext } from "./ThemeContext"
import { Moon, Sun } from "./Icons"

const ThemeToggle = () => {
  const { theme } = useContext(ThemeContext)

  if (theme === null) return <ThemeTogglePlaceholder />

  return (
    <Switch
      onChange={checked => {
        window.__setPreferredTheme(checked ? "dark" : "light")
      }}
      checked={theme === "dark"}
      checkedIcon={<Moon />}
      uncheckedIcon={<Sun />}
      onColor="#000"
      offColor="#0085ff"
      height={20}
      width={40}
    />
  )
}

const ThemeTogglePlaceholder = styled.div`
  height: 20px;
  width: 40px;
`

export default ThemeToggle
